import axios from 'axios';
import { ElLoading, ElMessageBox } from 'element-plus';
import router from '../router/index';

// axios.default.timeout = 500000;
// axios.default.withCredentials = true;
// axios.default.baseURL = 'http://10.168.254.127:8080/';

let instance = axios.create({
    timeout: 500000,
    withCredentials: true,
    baseURL: '/api'    //baseURL: '/back/api'
})

let loading;       //定义loading变量
let messageBoxFlag = false;       //定义messageBox变量

function startLoading() {    //使用Element loading-start 方法
  loading = ElLoading.service({
    lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading() {    //使用Element loading-close 方法
  loading.close()
}

//那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
//声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
//调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0

export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

//http request 拦截器
instance.interceptors.request.use(
  config => {
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    config.headers = {
      'Content-Type': "application/json",
      'token': localStorage.getItem('user_token'),
      'api-version': '1.0.0'
    };

    if (!config.noLoading) {
      showFullScreenLoading();
    }
    
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//http response 拦截器
instance.interceptors.response.use(
  response => {
    tryHideFullScreenLoading();
    if (!response.data.resultState && response.data.error == 'session-died') {
      localStorage.removeItem('user_token')
      router.push({//跳转回登录界面
        path: "/login"
      })
      if (!messageBoxFlag) {
        messageBoxFlag = true;
        ElMessageBox.alert("登录过期，请重新登录！", '提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: action => {
              messageBoxFlag = false;
              localStorage.removeItem('user_token')
              //var storage = window.localStorage;
              //storage.clear();
              // router.push({//跳转回登录界面
              //   path: "/"
              // })
            }
          });
        }
    } else if (response.data.resultState !== '200' && response.data.code !== '200' && response.status != '200') {
      ElMessageBox.alert(`${response.data.msg}`, '提示', {
        confirmButtonText: '确定'
      });
    }
    
    // if (response.data.resultState && (response.data.resultState != 1 && response.data.resultState != "200")) {
    //   if (!messageBoxFlag) {
    //     ElMessage.alert(response.data.msg, '提示', {
    //       confirmButtonText: '确定',
    //       showClose: false,
    //       callback: action => {
    //         messageBoxFlag = false;
    //       }

    //     });
    //   }
    // } else if (!response.data.resultState && response.data.error == 'session-died') {
    //   if (!messageBoxFlag) {
    //     messageBoxFlag = true;
    //     ElMessage.alert("登录过期，请重新登录！", '提示', {
    //       confirmButtonText: '确定',
    //       showClose: false,
    //       callback: action => {
    //         messageBoxFlag = false;
    //         var storage = window.localStorage;
    //         storage.clear();
    //         // router.push({//跳转回登录界面
    //         //   path: "/"
    //         // })
    //       }
    //     });
    //   }
    // } else if (response.data.resultState == '203' || response.data.error == 'Login') {
    //   if (!messageBoxFlag) {
    //     messageBoxFlag = true;
    //     ElMessage.alert("登录过期，请重新登录！", '提示', {
    //       confirmButtonText: '确定',
    //       showClose: false,
    //       callback: action => {
    //         messageBoxFlag = false;
    //         var storage = window.localStorage;
    //         storage.clear();
    //         // router.push({//跳转回登录界面
    //         //   path: "/"
    //         // })
    //       }
    //     });
    //   }
    // }
    return response;
  },
  error => {
    tryHideFullScreenLoading()
    // if (error.response) {
    //   if (!messageBoxFlag) {
    //     messageBoxFlag = true;
    //     // ElMessage.alert(error.response.statusText, '提示', {
    //     //   confirmButtonText: '确定',
    //     //   showClose: false,
    //     //   callback: action => {
    //     //     messageBoxFlag = false;

    //     //   }
    //     // });
    //   }

    // } else {
    // //   router.push({//跳到错误页面
    // //     path: "error"
    // //   })
    // }
    return Promise.reject(error)
  }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, data = {}, noLoading = true) {
  return new Promise((resolve, reject) => {
    instance.get(url, data, { noLoading: noLoading })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postDownload(url, data = {}, noLoading = false) {
  return new Promise((resolve, reject) => {
    instance.post(url, data, { noLoading: noLoading, responseType: 'blob' })
      .then(response => {
        const data = response;
        // if (data.succeed !== 0) {
        //     ElMessage.error(`${data.errorMsg}`);
        // }
        resolve(data);
      }, err => {
        console.log(err)
        reject(err)
      })
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}, noLoading = true) {
  return new Promise((resolve, reject) => {
    instance.patch(url, data, { noLoading: noLoading })
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}, noLoading = true) {
  return new Promise((resolve, reject) => {
    instance.put(url, data, { noLoading: noLoading })
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

 export function del(url, data = {}, noLoading = true) {
  return new Promise((resolve, reject) => {
    instance.delete(url, data, { noLoading: noLoading })
      .then(response => {
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}

