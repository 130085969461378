<template>
  <div style="margin-bottom: 20px;">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>敏感特征品控</el-breadcrumb-item>
    </el-breadcrumb>
  </div>

  <el-card shadow="always">
    <div class="title">信息筛选</div>
    <div class="search-content">
      <div style="display:flex;">
        <div style="display:flex;align-items:center;">
          <div style="width:100px;">股票名称</div>
          <el-input v-model="value" placeholder="请输入股票名称" style="width: 100%"></el-input>
        </div>
        <div class="search-btn" @click="search">筛选</div>
      </div>
    </div>
  </el-card>

  <el-card shadow="always" style="margin-top: 20px;">
    <div class="info-header">
      <div class="info-header_title">信息列表</div>
      <div class="info-header_btn">
        <div @click="exportData">数据导出</div>
      </div>
    </div>
    <div class="content">
      <el-table :data="tableData" :header-cell-style="{'text-align': 'center','font-weight': 'bold',color: '#333'}" :cell-style="{'text-align': 'center',color: '#333'}" style="width: 100%">
        <el-table-column prop="stockCode" label="股票代码" width="120" />
        <el-table-column prop="stockName" label="股票名称" />
        <el-table-column prop="volumeScore" label="成交量评分" />
        <el-table-column prop="volumeInterval" label="成交量定义" />
        <el-table-column prop="valuationScore" label="估值评分" />
        <el-table-column prop="valuationInterval" label="估值定义" />
        <el-table-column prop="periodScore" label="周期评分" />
        <el-table-column prop="periodInterval" label="周期定义" />
      </el-table>

      <div class="page">
        <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </el-card>

</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { getSensitiveFeatures, exportFeaturesExcel } from "../../../api/qualityControl/index";
export default {
  setup() {
    const state = reactive({
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      total: 0,
      value: ''
    });

    onMounted(() => {
      search();
    })

    const search = () => {
      let params = {
        content: state.value ? [].concat(state.value) : [],
        field: "",
        pageNo: state.currentPage,
        pageSize: state.pageSize,
        sequence: true
      }
      getSensitiveFeatures(params).then((res) => {
        if (res.resultState === '200') {
          const { list, total } = res.data;
          state.tableData = list;
          state.total = total;
        }
      }).catch((err) => {

      });
    }

    const handleSizeChange = (val) => {
      state.pageSize = val;
      search();
    };

    const handleCurrentChange = (val) => {
      state.currentPage = val;
      search();
    };

    const exportData = () => {
      let params = state.value ? [].concat(state.value) : []
  
      exportFeaturesExcel(params).then((res) => {
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        let url = window.URL.createObjectURL(blob);
        let aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = url;
        aLink.setAttribute("download", '敏感特征品控信息表.xlsx'); // 下载的文件
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
        window.URL.revokeObjectURL(url);
      }).catch((err) => {

      });
    }

    return {
      ...toRefs(state),
      search,
      handleSizeChange,
      handleCurrentChange,
      exportData
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    margin-left: 20px;
  }
}
.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    font-weight: bold;
  }
  &_btn {
    background: rgb(56, 162, 138);
    width: 120px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
}
.content {
  margin-top: 20px;
  .page {
    float: right;
    margin: 20px 0;
  }
}
</style>
